import { Link } from "react-router-dom";
import '../components/NotFound/NotFound.css'; 

export function NotFound() {
  return (
    <div className="not-found">
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}