import { HomeCV } from "../components/HomeCV/HomeCV";
import { Recents } from "../components/Recents/Recents";

import '../components/Home/Home.css';

export function Home() {
  return (
    <div className="home">
      <h1 >Hi,<br/> I'm Hanna Margrét and I'm a <br/> Software Developer!</h1>
      <Recents />
      <HomeCV />
    </div>
  );
}