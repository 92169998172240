import { Routes, Route } from "react-router-dom";

import './App.css';
import { NotFound } from "./pages/NotFound";
import { Home } from "./pages/Home";
import { AboutMe } from "./pages/AboutMe";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
// import { Projects } from "./components/Projects/Projects";
import { Contact } from "./components/Contact/Contact";
import { CVpage } from "./pages/CVpage";
import LoginForm from "./components/Login/Login";
import { AuthProvider } from './pages/AuthContext'; 

function App() {
  return (
    <div className="App">
      <Header />
      <AuthProvider>
      <Routes>
          <Route index element={<Home />} />
          <Route path="/about" element={<AboutMe />} />
          {/* <Route path="/projects" element={<Projects />} /> */}
          <Route path="/CV" element={<CVpage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects/login" element={<LoginForm />} />
          <Route path="*" element={<NotFound />} />
      </Routes>
      </AuthProvider>
      <Footer />
    </div>
  );
}

export default App;
