import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
    return (
        <header>
        <nav>
        <Link to="/" className="refresh-link">
          <img src="/Logo.png" alt="HMJ Logo" className="logo" />
        </Link>
          <ul className="menu">
            <li><Link to="/about">About</Link></li>
            {/* <li><Link to="/projects">Projects</Link></li> */}
            <li><Link to="/cv">CV</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </nav>
      </header>
    );
}

export default Header;
