
import '../components/Home/Home.css';
import { CV } from "../components/CV/CV";

export function CVpage() {
  return (
    <div >
        <CV />
    </div>
  );
}