import React from 'react';
import './About.css';

export function About() {
    return (
        <div className="about">
            <img src="/IMG_4546_3.jpg" alt="Hanna Margrét" className="about-photo" />
            <h2>Hi, I'm Hanna Margrét Jónsdóttir!</h2>
            <p>I am an MSc Computing (Software Engineering) student at Imperial College London, 
                with a BS degree in Software Engineering from the University of Iceland.
                My passion for programming began when I studied Data Structures in Java during a summer program at Columbia University.
                Since changing my major, I have enjoyed all my modules, particularly designing and understanding how applications are built from the ground up.
                I served on the board of Ada, a student association at the University of Iceland that 
                supports women and non-binary individuals in tech. I am passionate about promoting equality and making a positive impact in the industry. 
                Currently, I am part of the Women in Computing committee at Imperial, where I contribute as an outreach officer.
                In my free time, I enjoy running and completed my first marathon in 2023. 
                I also love socializing with friends and family and traveling. I have lived in New York City for a semester and am now enjoying life in London!
            </p>
        </div>
    );
    }