import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import './CV.css'; 

export function CV() {
    return (
        <div className="cv-container">
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                <div className="pdf-viewer-container">
                    <Viewer 
                        fileUrl="/CV_HannaJonsdottir.pdf" 
                        defaultScale={1.0}
                    />
                </div>
            </Worker>
            <p>
                <a href="/CV_HannaJonsdottir.pdf" download="CV_HannaJonsdottir.pdf" className="link">Download it here</a>
            </p>
        </div>
    )
}
