import React from 'react';
import InfoBox from '../InfoBox/InfoBox';
import './Recents.css'; 

export function Recents() {
    return (
        <div className="recents">
            <div className="recents-container">
                <InfoBox 
                    title="About Me" 
                    summary="Learn more about who I am and how I started in software" 
                    linkTo="/about" 
                />
                {/* <InfoBox 
                    title="Projects" 
                    summary="Check out some of my recent projects." 
                    linkTo="/projects"
                /> */}
                <InfoBox 
                    title="CV" 
                    summary="Take a look at my Curriculum Vitae." 
                    linkTo="/CV"
                />
                <InfoBox 
                    title="Contact" 
                    summary="Get in touch!" 
                    linkTo="/contact"
                />
            </div>
        </div>
    );
}
