import React from 'react';
import { Link } from 'react-router-dom';
import './InfoBox.css';

interface InfoBoxProps {
  title: string;
  summary: string;
  linkTo: string;
}

const InfoBox: React.FC<InfoBoxProps> = ({ title, summary, linkTo }) => {
  return (
    <Link to={linkTo} className="info-box-container">
      <div className="info-box">
        <h3>{title}</h3>
        <p>{summary}</p>
      </div>
    </Link>
  );
}

export default InfoBox;
