import React from 'react';
import './HomeCV.css';

export function HomeCV() {
    const experienceData = [
        {
          title: "MSc Computing (Software Engineering) - Imperial College London",
          period: "September 2024 - September 2025",
          description: "Currently on a year long Master's program in Computing, specializing in Software Engineering."
        },
        {
          title: "BS Software Engineering - University of Iceland",
          period: "August 2022 - June 2024",
          description: "Graduated with a first class distinction in June 2024."

        },
        {
          title: "Exchange semester - The Cooper Union",
          period: "August 2023 - December 2023",
          description: "I went on an exchange semester to The Cooper Union in New York City. I took these modules; Data Visualization, Engineering Management, Senior Electrical Engineering Project, Programming for Electrical Engineering and Software Project 1 remotely. I loved my time in New York and will be sure to return soon to catch up with friends and professors, I believe that going out of your comfort zone is the best way to grow as a person!"
        },
        {
            title: "Board of Ada",
            period: "September 2022 - May 2024",
            description: "I sat on the board of Ada, a student association at the University of Iceland. Ada is dedicated to creating a safe space for women and non-binary individuals within the university. They organize events, workshops, and lectures to promote equality and diversity within the field of Computer Science and Software Engineering."
        },
        {
          title: "Double Jaw Surgery",
          period: "October 2022 ",
          description: "I underwent double jaw surgery in the middle of the semester of fall 2022, which was very challenging since I was not able to chew for 6 weeks following the surgery. However, I passed all my classes and am now fully recovered. I am very proud of myself for pushing through and not giving up."
        },
        {
          title: "Summer Program - Columbia University",
          period: "July 2022 - August 2022",
          description: "I got the opportunity to attend Columbia University's summer program where I studied Data Structures in Java and Corporate Finance. This is where i realized the immense impact of programming and I fell in love with it."
        },
        {
          title: "BS Mechanical Engineering - University of Iceland",
          period: "August 2021",
          description: "I started my undergraduate studies in Mechanical Engineering at the University of Iceland."
        },
      ];

  return (
    <div className="homeCV">
      <h2>MY JOURNEY</h2>
      <div className="experience-list">
        {experienceData.map((exp, index) => (
          <div key={index} className="experience-box">
            <h3>{exp.title} </h3>
            <span className="experience-period">{exp.period}</span>
            <p className="experience-description">{exp.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
