import React from 'react';
import './Contact.css';

export function Contact() {
    return (
        <div className="contact">
            <h2>CONTACT ME</h2>
            <a href="https://www.linkedin.com/in/hanna-margret/" target="_blank" rel="noopener noreferrer">
                <img src="/linkedin.png" alt="LinkedIn" className="contact-icon" />
            </a>
            <a href="mailto:hanna.m.jonsd@gmail.com">
                <img src="/mail.png" alt="Email" className="contact-icon" /> 
                {/* <p> hanna.m.jonsd@gmail.com </p> */}
            </a>
            <a href="https://discordapp.com/users/4211" target="_blank" rel="noopener noreferrer">
                <img src="/discord.png" alt="Discord" className="contact-icon" />
            </a>
            <a href="https://github.com/hannajonsd" target="_blank" rel="noopener noreferrer">
                <img src="/github.png" alt="GitHub" className="contact-icon" />
            </a>
        </div>
    );
}
